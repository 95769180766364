<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="admin-login-page">
<!--    <div class="bg">
      <el-image :src="login_logo"></el-image>
    </div>-->
    <main>
      <el-form :model="form" ref="form" class="login-form">
        <el-form-item label="账号" prop="mobile_phone" class="form-item">
          <el-input v-model="form.account" auto-complete="off"/>
        </el-form-item>
        <el-form-item label="密码" prop="password" class="form-item">
          <el-input :type="passwordType" v-model="form.password" auto-complete="off">
            <i class="el-icon-view el-input__icon"
               slot="suffix"
               @click="handleIconClick">
            </i>
          </el-input>
        </el-form-item>

<!--        <el-form-item class="form-item" style="padding-top: 30px">
          <el-input v-model="form.img_code_id" style="width: 120px;padding-top: 0px" auto-complete="off"></el-input>
          <el-image :src="filePath" @click="getCaptchaImage" fit="contain"
                    style="width: 128px; height: 40px;float: right;padding-left: 20px;"></el-image>
        </el-form-item>-->
        <el-form-item class="form-item" style="margin-top:20px;">
          <el-button type="primary" size="medium" style="border-radius: 4px;" @click="submitForm" class="form-submit">提交</el-button>
        </el-form-item>
      </el-form>
    </main>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  data: function () {
    return {
      fullscreenLoading: false,
      passwordType: 'password',
      captcha_src: '',
      filePath: null,
      form: {
        account: '',
        password: '',
        session_id: '',
        img_code_id: '',
      }

    }
  }, mounted() {
    //this.getCaptchaImage();
  },
  methods: {
    handleIconClick() {
      return this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    submitForm() {
      let _this = this
      _this.fullscreenLoading = true
      let params = {
        "username": _this.form.account,
        "password":_this.$md5(_this.form.password)
      }

      adminHttp.post('/backend/admin/login',params).then(result => {
        if (result.code === 200) {
          _this.$store.commit('setCurrentAdmin', result.data);
          _this.$message.success("登陆成功！");
          _this.$router.push({path: '/admin/home_page/index'})
        } else {
          _this.$message.error(result.message || "登录失败，账号或密码错误！")
          _this.$store.commit("logout");
        }
      }).catch(error=>{
        // console.log(error)
        _this.$store.commit("logout");
        _this.$message.error('请求失败')
      }).finally(()=>{
        _this.fullscreenLoading = false
      })
    }, getCaptchaImage() {
      adminHttp.get('/sms/get_img_code').then(result => {
        if (result && result.code === 200) {
          this.filePath = `data:image/png;base64,` + result.code_img_data;
          this.form.session_id = result.img_code_id;
        } else{
          _this.$message.error(result.message)
        }
      }).catch(error => {
        this.$message.error('请求失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-login-page {
  height: 100%;
  overflow: hidden;
  min-height: 600px;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  header {
    padding: 1rem 0;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
    align-items: center;

    .logo {
      display: inline-block;
      height: 2rem;
      width: auto;
      margin: 0;
    }

    .redirect-to-office-website {
      color: #fff;
      text-decoration: none;
      border: 1px solid #fff;
      padding: .25rem 1rem;
      font-size: .8rem;
    }
  }

  main {
    flex: 1;
    display: flex;
    justify-items: center;
    justify-content: center;
    /*align-items: center;*/
    align-content: center;

    .login-form {
      border-radius: 5px;
      width: 20rem;
      background: #fff;
      padding: 1rem 2rem;
      margin: 1rem;

      .form-item {
        margin-bottom: 0;

        .redirect-to-forget-password {
          color: #3A7DC5;
          font-size: 12px;
          text-decoration: none;
        }

        .form-submit {
          width: 100%;
        }
      }
    }
  }

  footer {
    background: #2069BE;
    padding: 1rem;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .admin-login-page {
    header {
      width: 90%;
    }
  }
}

.bg {
  padding-top: 10px;
  margin: 0 auto;
  width: 20%;
  max-width: 300px;
}
</style>
